@import url('https://fonts.googleapis.com/css?family=Lato:100');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: 'Lato', monospace;
  margin: 0;
}

/* IE 11 Flex height fix */
.splash,
.brief,
.contact {
  height: 1px;
}

/*Full height tile*/
.tile {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tile__center {
  text-align: center;
}

.tile__text {
  font-size: 6vw;
  font-weight: normal;
}

.tile__pad {
  padding: 0 15vw;
}

/*Splash Tile*/
.splash__title {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 10vw;
  margin: 0 0 10px;
}

@media (min-width: 768px) {
  p.splash__subtitle,
  p.not-found__return {
    font-size: 2vw;
  }
  *.tile__text {
    font-size: 4vw;
  }
  div.segment__title {
    font-size: 3vw;
  }
  i.segment__toggle {
    font-size: 2vw;
  }
  div.contact__via {
    flex-direction: row;
  }
  div.contact__option {
    font-size: 2vw;
  }
  div.contact__option:nth-of-type(2) {
    order: 0;
  }
  div.contact__option img {
    height: 2vw;
  }
  i.contact__icon {
    font-size: 8vw;
  }
  p.contact__place {
    font-size: 3vw;
  }
  div.contact__map {
    width: 600px;
    height: 600px;
  }
  iframe.contact__frame {
    left: 0;
    top: 0;
  }
  div.segment__container {
    font-size: medium;
  }
  div.projects__carousel {
    padding: 0 30px;
  }
}

@media (min-width: 992px) {
  div.stack__block {
    display: flex;
  }
  .segment:not(:last-child) {
    margin-right: 2vw;
  }
}

.splash__subtitle {
  font-size: 3vw;
  margin: 0;
}

.splash__subtitle-segment:not(:first-child):before {
  content: '|';
  padding: 0 1vw;
}

.splash,
.not-found {
  color: #ffffff;
  background-color: #028482;
}

/*Not found page*/
.not-found__icon {
  font-size: 10vw;
}

.not-found__title {
  font-size: 5vw;
  margin: 0 0 1vw;
}

.not-found__return {
  font-size: 2vw;
  color: inherit;
  text-decoration: none;
}

/*Brief tile*/
.brief__newton {
  margin-top: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Stack*/
.stack__block {
  justify-content: space-between;
  flex-wrap: wrap;
}

.segment {
  flex: 1;
}

.segment--open .segment__container pre[class*='language-'] {
  transition: max-height 1s linear;
  max-height: 1000px;
  padding: 1em;
}

.segment__container {
  font-size: 3vw;
}

.segment__container pre[class*='language-'] {
  transition: padding 0s linear 0.2s, max-height 0.2s linear;
  max-height: 0;
  overflow: hidden !important;
  padding: 0;
}

.segment__title {
  font-size: 5vw;
  display: flex;
  justify-content: space-between;
}

.segment__actions {
  padding: 0 5px;
  transition: transform 0.2s linear;
}
.segment--open .segment__actions {
  transform: rotateZ(-180deg);
}

.segment__toggle {
  cursor: pointer;
  font-size: 4vw;
  color: #747474;
}

/*Projects*/
.project {
  background: white;
  box-sizing: border-box;
  flex: 1;
  margin: 30px;
  height: 370px;
  padding: 20px;
  text-decoration: none;
  color: black;
}

.projects__container {
  background: #bfbfbf;
  padding: 30px 0;
  overflow-x: auto;
}

.projects__carousel {
  display: flex;
  width: 2100px;
}

.projects__title {
  margin-top: 15vw;
}

.project__header {
  height: 20%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.project__logo-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  left: 0;
  width: 100px;
}

.project__name {
  font-weight: bold;
  margin: 8px 8px 0 0;
  text-transform: uppercase;
  text-align: right;
}

.project__description {
  margin: 30px 0 0 0;
  padding: 10px;
  font-size: 1.5em;
}

.project.project--speculative {
  height: 18vw;
}

.project__logo {
  max-width: 100%;
  height: 100%;
}

/* Contact */
.tile.contact {
  justify-content: space-around;
  position: relative;
}

.contact__place {
  font-size: 8vw;
}

.contact__map {
  border-radius: 300px;
  overflow: hidden;
  transition: all 2s;
  width: 300px;
  height: 300px;
  align-self: center;
}

.contact__frame {
  transition: all 2s;
  position: relative;
  top: -150px;
  left: -150px;
  width: 600px;
  height: 600px;
}

.contact__map.contact__map--minimized {
  width: 0;
  height: 0;
}

.contact__map--minimized .contact__frame {
  left: -300px;
  top: -300px;
}

.contact__map--hidden {
  display: none;
}

.map__icon {
  cursor: pointer;
}

.map__icon--hidden {
  display: none;
}

.contact__icon {
  font-size: 15vw;
}

.contact__via {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact__option {
  padding: 1px 0;
  flex: 1;
  text-align: center;
  font-size: 4vw;
}

.contact__option:nth-of-type(2) {
  order: -1;
}

.contact__option img {
  height: 4vw;
}

.contact__option a {
  text-decoration: none;
  color: black;
}

.footer {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

/* Netwon's Cradle animation */

.swing div {
  border-radius: 50%;
  float: left;
  height: 1em;
  width: 1em;
  background: #028482;
}
.shadow {
  clear: left;
  padding-top: 1.5em;
}
.shadow div {
  -webkit-filter: blur(1px);
  filter: blur(1px);
  float: left;
  width: 1em;
  height: 0.25em;
  border-radius: 50%;
  background: #e3dbd2;
}
.shadow .shadow__left {
  background: #d5d8d6;
}
.shadow .shadow__right {
  background: #eed3ca;
}
@-webkit-keyframes ball__left {
  0%,
  50% {
    transform: rotate(0) translateX(0);
  }
  100% {
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@keyframes ball__left {
  0%,
  50% {
    transform: rotate(0) translateX(0);
  }
  100% {
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@-webkit-keyframes ball__right {
  0% {
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    transform: rotate(0) translateX(0);
  }
}
@keyframes ball__right {
  0% {
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    transform: rotate(0) translateX(0);
  }
}
@-webkit-keyframes shadow__left-n {
  0%,
  50% {
    opacity: 0.5;
    transform: translateX(0);
  }
  100% {
    opacity: 0.125;
    transform: translateX(-1.75em);
  }
}
@keyframes shadow__left-n {
  0%,
  50% {
    opacity: 0.5;
    transform: translateX(0);
  }
  100% {
    opacity: 0.125;
    transform: translateX(-1.75em);
  }
}
@-webkit-keyframes shadow__right-n {
  0% {
    opacity: 0.125;
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: 0.5;
    transform: translateX(0);
  }
}
@keyframes shadow__right-n {
  0% {
    opacity: 0.125;
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: 0.5;
    transform: translateX(0);
  }
}
.swimg__left {
  -webkit-animation: ball__left 0.425s ease-in-out infinite alternate;
  animation: ball__left 0.425s ease-in-out infinite alternate;
}
.swimg__right {
  -webkit-animation: ball__right 0.425s ease-in-out infinite alternate;
  animation: ball__right 0.425s ease-in-out infinite alternate;
}
.shadow__left {
  -webkit-animation: shadow__left-n 0.425s ease-in-out infinite alternate;
  animation: shadow__left-n 0.425s ease-in-out infinite alternate;
}
.shadow__right {
  -webkit-animation: shadow__right-n 0.425s ease-in-out infinite alternate;
  animation: shadow__right-n 0.425s ease-in-out infinite alternate;
}
